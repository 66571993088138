import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Button } from '@mui/material';
import useWindowSize from '../../hooks/useWindowSize';
import FeedbackForm from '../../components/Forms/Feedback';

function ResultPage({ result, handleReset }) {
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const [showFeedback, setShowFeedback] = useState(false);
    const [hasShownFeedback, setHasShownFeedback] = useState(false);

    useEffect(() => {
        const feedbackShown = localStorage.getItem('feedbackShown');
        if (feedbackShown) {
            setHasShownFeedback(true);
        }
    }, []);

    const handleStartOver = () => {
        if (!hasShownFeedback) {
            setShowFeedback(true);
            setHasShownFeedback(true);
            localStorage.setItem('feedbackShown', 'true');
        } else {
            handleReset();
        }
    };

    if (!result) {
        return null; // Return null if the result is empty
    }

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Box flexGrow={1} overflow="auto">
                <Box className={`text-left ${isMobile ? 'h-[calc(100dvh-12rem)]' : 'h-auto'}`}>
                    <Box className="p-4 text-black font-serif">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                h3: ({ node, ...props }) => <h3 style={{ fontWeight: 'bold' }} {...props} />,
                                ul: ({ node, ...props }) => <ul style={{ marginLeft: '20px' }} {...props} />,
                                li: ({ node, ...props }) => <li style={{ marginBottom: '10px' }} {...props} />,
                                strong: ({ node, ...props }) => <strong style={{ fontWeight: 'bold' }} {...props} />,
                                p: ({ node, ...props }) => <p style={{ marginBottom: '15px' }} {...props} />,
                            }}
                        >
                            {result}
                        </ReactMarkdown>
                    </Box>
                </Box>
            </Box>
            <Box mt={2} mb={4} display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleStartOver}
                    className="font-serif py-3 px-6 rounded-full text-black text-center bg-white hover:bg-slate-200 w-full md:w-auto"
                >
                    Start Over
                </Button>
            </Box>
            <FeedbackForm
                open={showFeedback}
                setOpen={(isOpen) => {
                    setShowFeedback(isOpen);
                    if (!isOpen) {
                        handleReset();
                    }
                }}
            />
        </Box>
    );
}

export default ResultPage;